// i18next-extract-mark-ns-start documents

import React, { FC } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import DownloadSvg from 'src/images/icons/download.inline.svg'
import { IAudioCategory } from './audio-types'
import AudioPlayer from './AudioPlayer'
import PlusIcon from '../icons/PlusIcon'
import themeColors from 'src/helpers/theme-colors'
import HeightAnitmation from 'src/components/layout/HeightAnimation'
import AudioSubcategories from './AudioSubcategories'

interface IAudioCategoryProps {
  audioCategory: IAudioCategory
  showSubcategoriesBottomBorder: boolean
  isOpen: boolean
  onToggleOpen: () => void
  className?: string
}

const AudioCategory: FC<IAudioCategoryProps> = ({
  audioCategory,
  showSubcategoriesBottomBorder,
  isOpen,
  onToggleOpen,
  className,
}) => {
  const { t } = useTranslation('audio')

  const blockBubbling = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation()
  }

  const rotateAnimation = `transition-all ease-in-out duration-500${
    isOpen ? ' transform rotate-45' : ''
  }`
  const downloadActionText = t('Download')
  const recording = audioCategory.recording
  const url = recording?.url
  const subcategories = audioCategory.subcategories
  const hasSubcategories = subcategories.length > 0
  const hasRecording = !!recording

  const onClickedOrPressed = hasSubcategories ? onToggleOpen : undefined
  
  return (
    <>
      <div 
        className={`p-3 transition-all ease-in-out duration-500 leading-tight text-lg text-deepDarkBlue border-deepDarkBlue flex justify-between items-stretch${
            isOpen ? " bg-brightYellow" : ''
          }${
            hasSubcategories ? " cursor-pointer" : ''
          }${
            className ? ` ${className}` : ''
          }`
        }
        onClick={ onClickedOrPressed }
        onKeyPress={ onClickedOrPressed }
        role={ hasSubcategories ? "button" : undefined }
        tabIndex={0}
      >
        <div className="shrink w-full min-h-[2rem] flex flex-col sm:flex-row sm:justify-between">
          <p className="my-auto">{audioCategory.title}</p>
          { hasRecording && 
            <AudioPlayer 
              content={recording} 
              className="w-full max-w-[14rem] h-8 ml-3 mt-3 sm:my-auto cursor-auto" 
            />
          }
        </div>
        <div className="pl-4 sm:pl-3 flex flex-col items-center sm:flex-row-reverse">
          { hasSubcategories 
              ? <button type="button" className="flex-1">
                <PlusIcon
                  className={`w-6 h-6 my-auto sm:ml-3 ${rotateAnimation}`}
                  width="1.5px"
                  color={themeColors.navyBlueGray}
                />
              </button>
              : <div className="w-6 sm:ml-3 flex-1" />
          }
          { hasRecording &&
            <div className="w-6 h-8 mt-3 sm:mt-0 flex-none flex items-center justify-center">
              <a href={url} download onClick={blockBubbling}>
                <DownloadSvg
                  title={downloadActionText}
                  aria-label={downloadActionText}
                  className="w-5 h-6 max-w-none block stroke-current text-navyBlueGray"
                />
              </a>
            </div>
          }
          
        </div>
      </div>
      { hasSubcategories &&
        <HeightAnitmation isOpen={isOpen}>
          <AudioSubcategories 
            items={subcategories} 
            showBottomBorder={showSubcategoriesBottomBorder}
          />
        </HeightAnitmation>
      }
    </>
  )
}

export default AudioCategory
