import React, { FC } from "react";
import { IAudioSubcategory } from "./audio-types";
import AudioSubcategory from "./AudioSubcategory";

interface IAudioSubcategoriesProps {
  items: Array<IAudioSubcategory>
  className?: string
  showBottomBorder: boolean
}

const AudioSubcategories: FC<IAudioSubcategoriesProps> = ({ 
  items,
  showBottomBorder
}) => {
  return <div>
    { items.map((item, index) => 
      <AudioSubcategory 
        key={item.id}
        audioSubcategory={item} 
        showBottomBorder={ index < items.length - 1 || showBottomBorder }
      />
    )}
  </div>
}

export default AudioSubcategories