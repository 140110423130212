// i18next-extract-mark-ns-start audio

import React, { useState, useEffect, useRef, useContext } from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import PageTitle from 'src/components/typography/PageTitle'
import Section from 'src/components/typography/Section'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import Select from 'src/components/ui/Select'
import { ISelectOption } from 'src/components/ui/SelectOption'
import AudioCategories from 'src/components/audio/AudioCategories'
import { isBrowser } from 'src/helpers/layout'
import { getSeasonFromLocalStorage } from 'src/helpers/seasons'
import lawsStore from 'src/components/laws/laws-store'
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next'


interface IUrlParams {
  language?: string
  year?: string
}

const { latestSeason } = require('src/seasons')

const IndexPage = ({ pageContext: { audioCategories, seasons, languages } }) => {
  const { t } = useTranslation('audio')
  const lngContext = useContext(I18nextContext)
  const [selectedYear, setSelectedYear] = useState(null as null | ISelectOption)
  const [selectedLanguage, setSelectedLanguage] = useState(
    null as null | ISelectOption
  )
  const [openCategoryIds, setOpenCategoryIds] = useState(new Set<string>())
  
  const mainLawsSeason = lawsStore((state) => state.selectedSeason)

  const getUrlParams = (): IUrlParams => {
    if (!isBrowser) {
      return {}
    }
    const urlSearchParams = new URLSearchParams(window.location.search)
    return Object.fromEntries(urlSearchParams.entries())
  }

  const setUrlParam = (param: string, value: string) => {
    const url = new URL(window.location.toString())
    url.searchParams.set(param, value)
    window.history.pushState({}, '', url)
  }

  const handleYearSelect = (option: ISelectOption) => {
    setSelectedYear(option)
    setOpenCategoryIds(new Set())
    setUrlParam('year', option.optionId)
  }

  const handleLanguageSelect = (option: ISelectOption) => {
    setSelectedLanguage(option)
    setOpenCategoryIds(new Set())
    setUrlParam('language', option.optionId)
  }

  const getFilteredRecordings = () => 
    audioCategories
      .filter(({ locale, seasons }) => 
        locale === selectedLanguage?.optionId 
          && seasons?.includes(selectedYear.optionId)
      )
      .map((audioCategory) => ({
        ...audioCategory,
        subcategories: audioCategory.subcategories
          .filter(({ seasons }) => seasons?.includes(selectedYear.optionId))
      }))
      .filter(({ recording, subcategories }) => 
        recording || subcategories.length
      )
  
  const onCategoryToggleOpen = (categoryId: string) => {
    const newOpenIds = new Set(openCategoryIds)
    if (openCategoryIds.has(categoryId)) {
      newOpenIds.delete(categoryId)
    } else {
      newOpenIds.add(categoryId)
    }
    setOpenCategoryIds(newOpenIds)
  }

  const getDefaultYear = () => 
    getSeasonFromLocalStorage()?.year || latestSeason.year

  useEffect(() => {
    const urlParams = getUrlParams()
    const preferredLanguageOption = 
      languages.find((lng) => lng.optionId === urlParams.language)
        ?? languages.find((lng) => lng.optionId === lngContext.language)
        ?? languages.find((lng) => lng.optionId === lngContext.defaultLanguage)
        ?? languages[0]
    handleLanguageSelect(preferredLanguageOption)

    const hasRecordings = (season) => 
      audioCategories.some(({ locale, seasons }) => 
        locale === preferredLanguageOption.optionId && seasons.includes(season)
      )

    const defaultYear = getDefaultYear()

    const preferredSeasonOption = 
      seasons.find(({ optionId }) => optionId === urlParams.year)
        ?? seasons.find(({ optionId }) => 
          optionId === defaultYear && hasRecordings(optionId)
        )
        ?? seasons.find(({ optionId }) => hasRecordings(optionId))
    handleYearSelect(preferredSeasonOption)
  }, [])

  const isInitialRun = useRef(true)
  useEffect(() => {
    if (isInitialRun.current) {
      isInitialRun.current = false
    } else {
      const defaultYear = getDefaultYear()
      const yearToSelect = seasons.find(
        ({ optionId }) => optionId === defaultYear
      )
      if (yearToSelect) {
        handleYearSelect(yearToSelect)
      }
    }
  }, [mainLawsSeason])

  return (
    <>
      <Helmet>
        <title>{t('Audio files')}</title>
      </Helmet>
      <Layout>
        <div className="min-h-screen bg-white text-deepDarkBlue">
          <ContentPageContainer>
            <PageTitle>
              <span>{t('Laws of the game - audio')}</span>
            </PageTitle>
            <Section>
              <div className="mt-12 flex flex-wrap md:flex-nowrap justify-between">
                <Select
                  options={seasons}
                  onSelect={handleYearSelect}
                  selectedItem={selectedYear}
                  className="w-full md:w-1/2 md:mr-0.5 mt-4"
                />
                <Select
                  options={languages}
                  onSelect={handleLanguageSelect}
                  selectedItem={selectedLanguage}
                  className="w-full md:w-1/2 mt-4"
                />
              </div>
              <div className="mt-12">
                <AudioCategories 
                  items={getFilteredRecordings()}
                  openItemIds={openCategoryIds}
                  onItemToggleOpen={onCategoryToggleOpen}
                />
              </div>
            </Section>
          </ContentPageContainer>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
