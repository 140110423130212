import React, { FC } from "react";
import { IAudioSubcategory } from "./audio-types"
import AudioPlayer from "./AudioPlayer"
import DownloadSvg from 'src/images/icons/download.inline.svg'
import { useTranslation } from "react-i18next";

interface IAudioSubcategoryProps {
  audioSubcategory: IAudioSubcategory
  showBottomBorder: boolean
}

const AudioSubcategory: FC<IAudioSubcategoryProps> = ({ 
  audioSubcategory,
  showBottomBorder,
}) => {
  const { t } = useTranslation('audio')
  const downloadActionText = t('Download')
  const url = audioSubcategory.recording.url
  return <div className={`min-h-[2rem] ml-6 py-3 pr-3 flex flex-col sm:flex-row sm:justify-between sm:items-center leading-tight text-lg text-navyBlueGray border-navyBlueGray${
        showBottomBorder ? ' border-b' : ''
      }`}>
        <p>{audioSubcategory.title}</p>
        <div className="shrink-0 flex justify-between sm:justify-end items-end">
          <AudioPlayer 
            content={audioSubcategory.recording} 
            className="w-full h-8 max-w-[14rem] mt-3 sm:mt-0 cursor-auto" 
          />
          <div className="w-6 h-8 ml-4 sm:ml-3 sm:mr-9 flex items-center justify-center">
            <a href={url} download>
              <DownloadSvg
                title={downloadActionText}
                aria-label={downloadActionText}
                className="w-5 h-6 max-w-none block stroke-current text-navyBlueGray"
              />
            </a>
          </div>
        </div>
    </div>
}

export default AudioSubcategory