import React, { FC } from 'react'
import { IAudioCategory } from './audio-types'
import AudioCategory from './AudioCategory'

interface IAudioCategoriesProps {
  items: IAudioCategory[]
  openItemIds: Set<string>
  onItemToggleOpen: (itemId: string) => void
  className?: string
}

const AudioCategories: FC<IAudioCategoriesProps> = ({
  items,
  openItemIds,
  onItemToggleOpen,
  className,
}) => {  
  if (!items) {
    return <></>
  }

  const isOpen = (item: IAudioCategory): boolean => 
    openItemIds.has(item.id)

  return (
    <div className={className}>
      {items.map((item, index) => {
        const showTopBorder = index === 0 || isOpen(items[index - 1]) 
        return (
          <AudioCategory
            key={item.id}
            audioCategory={item}
            isOpen={isOpen(item)}
            onToggleOpen={() => onItemToggleOpen(item.id)}
            showSubcategoriesBottomBorder={ index === items.length - 1 }
            className={ showTopBorder ? "border-y" : "border-b" }
          />
        )
      })}
    </div>
  )
}

export default AudioCategories
